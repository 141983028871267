<template>
  <vuestic-widget :loading="loading">
    <div class="unsubscribe-email text-center">
      <h2>Subscription Management</h2>
      <div v-if="!success">
        <p>Are you sure you want to unsubscribe from our channel? Please click button below to unsubscribe.</p>
        <div class="row mt-5">
          <div class="col-md-12">
            <p>Please take a moment and let us know why you unsubscribed.</p>
            <textarea v-model="content" rows="6" class="custom-script-textarea w-100 p-2" placeholder="Your feedback here..."></textarea>
          </div>
          <div class="col-md-12 mt-4">
            <button @click="onUnsubscribe" class="btn btn-primary" style="min-width: 80px">
              <atom-spinner
                v-if="processing"
                slot="loading"
                :animation-duration="1500"
                :size="14"
                color="#FFF"
                style="margin: auto"
              />
              <span v-else>Unsubscribe</span>
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <p>You have successfully been unsubscribed from our channel.</p>
      </div>
    </div>
  </vuestic-widget>
</template>

<script>
import TextEditorPicker from "../common/TextEditorPicker";

export default {
  name: 'unsubscribe-email',
  components: {
    TextEditorPicker,
  },
  data () {
    return {
      loading: false,
      processing: false,
      success: false,
      content: '',
      token: null,
    }
  },

  mounted() {
    this.token = this.$route.params.token
    if (this.token)
    {
      this.verifyToken()
    } else {
      this.onError();
    }
  },

  methods: {
    onError() {
      this.$router.push({name: 'login'})
    },
    verifyToken() {
      this.loading = true
      const data = {
        token: this.token,
        access_type: 1
      }
      this.$store.dispatch('auth/verifyToken', data)
        .then(({ data, status }) => {
          this.loading = false
          if (status)
          {
            this.success = true
          }
        })
        .catch((err) => {
          this.onError();
        })
    },

    onUnsubscribe() {
      this.processing = true
      const data = {
        token: this.token,
        content: this.content,
        access_type: 1
      }
      this.$store.dispatch('auth/unsubscribe', data)
        .then(() => {
          this.processing = false
          this.success = true;
        })
        .catch((err) => {
          this.processing = false
        })
    }
  },

  computed: {
  }
}
</script>

<style lang="scss">
  .unsubscribe-email {

  }
</style>
